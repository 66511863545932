<template>
  <footer
    class="mt-auto text-sm py-1 px-2 text-center text-gray-400 grid grid-cols-1 md:grid-cols-3"
  >
    <div class="text-xs text-center order-last md:text-left md:order-first">
      <span :title="version.buildRef || $t('message.build.unknown-ref')"
        >v{{ version.package }}</span
      >
      <span
        v-if="hasVersionMismatch"
        class="text-yellow-500"
        :title="$t('message.build.version-mismatch')"
      >
        ⚠️ {{ version.build }}
      </span>
    </div>
    <p class="m-0">{{ $t('page.home.notice') }}</p>
    <div class="text-xs text-center md:text-right">
      <NuxtLink to="/terms-of-use">
        {{ $t('page.legal.terms-of-use') }}
      </NuxtLink>
      <NuxtLink to="/privacy" class="ml-4">
        {{ $t('page.legal.privacy') }}
      </NuxtLink>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRuntimeConfig } from '#app';

const config = useRuntimeConfig();
const version = ref({
  package: config.public.appVersion,
  build: config.public.buildVersion,
  buildRef: config.public.buildRef,
});

const hasVersionMismatch = computed(
  () => version.value.package !== version.value.build && version.value.build,
);
</script>
